<template>
  <Layout>
    <template v-if="this.$route.name == 'add-card'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" id="medinext">
            <b-form enctype="multipart/form-data" ref="promotion">
              <div class="row">
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                      <label for="template_title">Template Title <span style="color: red">*</span></label>
                      <b-form-input
                      type="text"
                      id="template_title"
                      placeholder="Enter Template Title"
                      v-model="form.title"
                      :class="{
                        'is-invalid': submitted && $v.form.title.$invalid,
                      }"
                      ></b-form-input>
                      <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                          Template Title is required.
                      </div>
                  </b-form-group>
                  <!-- <template>
                    <div> -->
                      <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="category">Category</label>
                        <multiselect
                          id="category-select"
                          v-model="form.category_id"
                          :options="categoryDropdown"
                          :multiple="false"
                          track-by="key"
                          placeholder="Select a category"
                          label="label"
                        >
                          <span slot="noOptions">Type here to search</span>
                        </multiselect>
                      </b-form-group>

                      <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="subcategory">Subcategory</label>
                        <multiselect
                          id="subcategory-select"
                          v-model="form.sub_category_id"
                          :options="subCategoryDropdown"
                          :multiple="false"
                          track-by="key"
                          placeholder="Select a subcategory"
                          label="label"
                          @search-change="fetchSubCategory(form.category_id, $event)"
                        >
                          <span slot="noOptions">Type here to search</span>
                        </multiselect>
                      </b-form-group>
                    <!-- </div>
                  </template> -->

                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                      <label for="type">Language </label>
                          <multiselect
                              @search-change="fetchLanguage"
                              id="input-multi"
                              v-model="form.language_id"
                              :options="languageDropdown"
                              :multiple="false"
                              track-by="key"
                              placeholder="Type here to search"
                              label="label"
                          >
                              <span slot="noOptions">Type here to search</span>
                          </multiselect>
                  </b-form-group>
                  <b-form-group id="input-group-2" class="col-lg-6 col-md-6 col-sm-12">
                    <label for="input-1" class="d-flex justify-content-between align-items-center">
                      <!-- Left Side: Format Code -->
                      <span
                        class="badge badge-primary p-1"
                        style="cursor: pointer"
                        @click="formatCode"
                        v-if="form.html_content"
                      >
                        Format Code
                      </span>

                      <!-- Center: HTML Content -->
                      <span>HTML Content</span>

                      <!-- Right Side: Preview Template -->
                      <span
                        class="badge badge-info btn-sm"
                        style="cursor: pointer"
                        @click="openPreviewInNewTab"
                      >
                        <span v-if="previewEmail">Close Preview</span>
                        <span v-else>Preview Template</span>
                      </span>
                    </label>

                    <!-- Ace Editor -->
                    <ace-editor
                      v-model="form.html_content"
                      @init="editorInit"
                      lang="html"
                      theme="chrome"
                      width="100%"
                      height="1000"
                      class="mb-3"
                    ></ace-editor>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12" v-if="previewEmail && form.html_content">
                    <div
                      v-html="form.html_content"
                      class="d-flex align-items-center"
                      style="height: 400px; overflow-y: auto;"
                    ></div>
                  </b-form-group>
                <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                  <label for="thumbnail_img">Template Image</label>
                  <b-form-file
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    id="thumbnail_img"
                    @change="readFile($event, 'thumbnail_img')"
                    ref="thumbnail_img"
                  >
                  </b-form-file>
                  <template
                    v-if="$route.name == 'edit-template' && edit.template_img_url"
                  >
                    <img
                      :src="edit.template_img_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="template_img_url">
                    <img
                      :src="template_img_url"
                      width="128px"
                      height="128px"
                      ref="template_img_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                        label="Enter Free tags"
                        label-for="tags-separators"
                        class="col-6"
                      >
                        <b-form-tags
                          input-id="tags-separators"
                          v-model="form.tags"
                          tag-variant="primary"
                          tag-pills
                          separator=" "
                          placeholder="Enter new tags separated by space and enter"
                          :class="{
                            'is-invalid': submitted && $v.form.tags.$invalid,
                          }"
                        >
                        </b-form-tags>
                        <div v-if="submitted && !$v.form.tags.required" class="invalid-feedback">
                          Template tags is required.
                      </div>
                      </b-form-group>
                <b-form-group
                  label="Select an type for template Download"
                  label-for="static-select"
                >
                  <b-form-select id="static-select" v-model="form.type">
                    <option value="" disabled>Select type</option>
                    <option value="image">IMAGE</option>
                    <option value="pdf">PDF</option>
                  </b-form-select>
                </b-form-group>
                <b-form-group id="input-group-26" class="col-12">
                  <div style="display: flex; gap: 10px">
                    <b-form-checkbox v-model="form.is_active"
                      >Is Active</b-form-checkbox
                    >
                    <b-form-checkbox v-model="form.custom_size"
                      >Custom Size</b-form-checkbox
                    >
                  </div>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                <span v-if="this.$route.name == 'add-template'"
                  >Save Data</span
                >
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import patientLibTempMixin from "../../../../mixins/ModuleJs/patient-lib-template";
  import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      submitted: false,
      title1: "Add Template",
      title2: "Edit Template",
      items: [
        {
          text: "List",
          href: "/patient-library/templates",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, patientLibTempMixin],
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  validations: {
    form: {
      title: { required },
      tags: { required },
      // camp_icon: { required }
    },
  },
};
</script>
