import {
    patient_library,
    patient_library_template
  } from "../../js/path";

  const beautify = require("js-beautify").html;
  
  export default {
    data() {
      return {
        previewEmail: false,
        isPaginate: true,
        pageNo: null,
        table_header: [],
        template_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "title",
            label: "Title"
          },
          {
            key: "category_name",
            label: "Category"
          },
          {
            key: "sub_category_name",
            label: "Sub Category"
          },
          {
            key: "language",
            label: "Language"
          },
          {
            key: "type",
            label: "Template Type"
          },
          {
            key: "is_active",
            label: "Status",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        categoryDropdown: [],
        subCategoryDropdown: [],
        languageDropdown:[],
        tags: [],
        form: {
          title: "",
          thumbnail_img:"",
          html_content:"",
          is_active: 0,
          custom_size:0,
          type:"",
          category_id: "",
          sub_category_id: ""
        },
        thumbnail_img: "",
        template_img_url: "",
        edit: {
          template_img_url: null,
        },
      };
    },
    computed: {
      
    },
    methods: {
      formatCode() {
        const formattedContent = beautify(this.form.html_content, {
          indent_size: 2,
        });
        this.form.html_content = formattedContent;
      },
      editorInit(editor) {
        if (editor) {
          editor.container.style.fontSize = "18px";
        } else {
          console.error("Editor instance is not defined.");
        }
      },
      openPreviewInNewTab() {
        const newWindow = window.open();
        newWindow.document.write(`
          <html>
            <head><title>${this.form.title}</title></head>
            <body>${this.form.html_content}</body>
          </html>
        `);
        newWindow.document.close();
      },
      readFile(e, txt) {
        if (txt == "thumbnail_img") {
          this.thumbnail_img = e.target.files[0];
          if (this.thumbnail_img.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded file is More than 2MB",
            });
            this.$refs.thumbnail_img.reset();
            this.template_img_url = "";
            this.edit.template_img_url = "";
            this.thumbnail_img = "";
            return false;
          } else {
            this.template_img_url = URL.createObjectURL(this.thumbnail_img);
            this.edit.template_img_url = "";
            return true;
          }
        } 
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        this.isPaginate = false;
        this.showData = false;
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library_template.patientLibraryTemplatesUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library_template.patientLibraryTemplatesUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library_template.patientLibraryTemplatesUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library_template.patientLibraryTemplatesUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
            patient_library_template.patientLibraryTemplatesUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = patient_library_template.patientLibraryTemplatesUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          this.isPaginate = true;
          this.showData = true;
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async updateStatus(id) {
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library_template.patientLibraryTemplatesupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            is_active: !this.tableData.data[index].is_active,
          });
          if (data.status) {
            const responseData = data.response;
            this.tableData.data[index] = responseData;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      },
      async fetchCategory() {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library.patientLibraryCategoryUrl + '?filter=active';
          const data = await this.getRequest(url);
          this.categoryDropdown = data.response.data.map((item) => ({
            key: item.id, // Value field
            label: item.title, // Text field
          }));
        } catch (error) {
          console.error("Error fetching dropdown data:", error);
        }
      },
      async fetchSubCategory(category_id,search) {
        console.log(search);
        if (!category_id) return; // If no category selected, return
        this.$store.commit("toast/updateStatus", true);
        try {
          let url =
            `${patient_library.patientLibrarySubCategoryUrl}?search=${search}&filter=active&category_id=${category_id.key}`;
          const data = await this.getRequest(url);
          this.subCategoryDropdown = data.response.data.map((item) => ({
            key: item.id, // Value field
            label: item.title, // Text field
          }));
        } catch (error) {
          console.error("Error fetching dropdown data:", error);
        }
      },
      async fetchLanguage() {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library.patientLibraryLanguagesUrl + '?filter=active';
          const data = await this.getRequest(url);
          this.languageDropdown = data.response.data.map((item) => ({
            key: item.id, // Value field
            label: item.title, // Text field
          }));
        } catch (error) {
          console.error('Error fetching dropdown data:', error);
        }
      },
      async fetchpatient_library_template(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = patient_library_template.patientLibraryTemplatesUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.tile != "" && responseData.title != null) {
              this.form.title = responseData.title;
            }
            this.form.html_content = responseData?.html_content ?? "";
            if (responseData.type != "" && responseData.type != null) {
              this.form.type = responseData.type;
            }
            if (
              responseData.type != "" &&
              responseData.type != null
            ) {
              this.form.type = responseData.type;
            }
            if (responseData.is_active) {
              this.form.is_active = true;
            }
            if (responseData.custom_size) {
              this.form.custom_size = true;
            }
            if (responseData.thumbnail_img != "" && responseData.thumbnail_img != null) {
              this.edit.template_img_url = responseData.thumbnail_img ? responseData.thumbnail_img : "";
            }
            this.form.category_id = {
              key: responseData?.category_id,
              label: responseData?.category_name
            }
            this.form.sub_category_id = {
              key: responseData?.sub_category_id,
              label: responseData?.sub_category_name
            }
            this.form.language_id = {
              key: responseData?.language_id,
              label: responseData?.language
            }

            if (responseData.tags != null && responseData.tags != "") {
              this.form.tags = responseData.tags;
            }
            this.template_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async deletepatient_library_template(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library_template.patientLibraryTemplatesUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restorepatient_library_template(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = patient_library_template.restorepatientLibraryTemplates + "/" + id;
          const data = await this.postRequest(url, {});
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = patient_library_template.patientLibraryTemplatesUrl;
          if (this.$route.name == "edit-template") {
            url = patient_library_template.patientLibraryTemplatesUrl + "/" + this.template_id;
          }
          let dataAppend = new FormData();
  
          if (this.form.is_active) {
            dataAppend.append("is_active", 1);
          } else {
            dataAppend.append("is_active", 0);
          }

          if (this.form.custom_size) {
            dataAppend.append("custom_size", 1);
          } else {
            dataAppend.append("custom_size", 0);
          }
  
          if (this.thumbnail_img) {
            dataAppend.append("thumbnail_img", this.thumbnail_img);
          }
  
          if (this.form.type) {
            // console.log(this.form.type);
            dataAppend.append("type", this.form.type);
          }

          if (this.form.category_id) {
            // console.log(this.form.campaign_id);
            dataAppend.append("category_id", this.form.category_id.key);
          }
          // if (this.form.category_id && this.form.sub_category_id && this.form.sub_category_id.key) {
          //   dataAppend.append("sub_category_id", this.form.sub_category_id.key);
          // } else {
          //   console.warn("Sub-category ID is missing or invalid.");
          //   dataAppend.append("sub_category_id", ""); 
          // }

          if (this.form.sub_category_id) {
            dataAppend.append("sub_category_id", this.form.sub_category_id.key);
          }

          if (this.form.language_id) {
            // console.log(this.form.campaign_id);
            dataAppend.append("language_id", this.form.language_id.key);
          }
  
          for (var key in this.form) {
            if (
              key != "is_active" &&
              key != "thumbnail_img" &&
              key != "custom_size" &&
              key != "tags" &&
              key != "category_id" &&
              key != "category_name" &&
              key != "sub_category_id" &&
              key != "sub_category_name" &&
              key != "language_id" &&
              key != "language"
            ) {
              dataAppend.append(key, this.form[key]);
            }
          }
  
          if (this.$route.name == "edit-template") {
            dataAppend.append("_method", "put");
          }
          const tags = this.form.tags.map((key) => key);
          tags.forEach((t) => dataAppend.append("tags[]", t));
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/patient-library/templates");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //        if (this.table_header.includes("delete")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           this.fields[index] = {
    //              key: "restore",
    //           };
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "delete"
    //           );
    //           this.table_header[table_index] = "restore";
    //        } else {
    //           this.table_header.push("restore");
    //           this.fields.push({
    //              key: "restore",
    //           });
    //        }
  
    //        if (!this.can("restore-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "restore"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "restore"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        }
  
    //        let index = this.fields.findIndex((item) => item.key == "edit");
    //        if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //        }
    //     } else {
    //        if (this.table_header.includes("restore")) {
    //           if (!this.can("edit-campaign_content")) {
    //              this.fields[6] = {
    //                 key: "delete",
    //              };
    //           } else {
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
  
    //           let table_index = this.table_header.findIndex(
    //              (item) => item == "restore"
    //           );
    //           this.table_header[table_index] = "delete";
    //        }
  
    //        if (!this.can("edit-campaign_content")) {
    //           let index = this.fields.findIndex((item) => item.key == "edit");
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "edit"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("edit")) {
    //              this.table_header.push("edit");
    //              this.fields[6] = {
    //                 key: "edit",
    //              };
    //           }
    //        }
  
    //        if (!this.can("delete-campaign_content")) {
    //           let index = this.fields.findIndex(
    //              (item) => item.key == "delete"
    //           );
    //           if (index !== -1) {
    //              this.fields.splice(index, 1);
    //              let table_index = this.table_header.findIndex(
    //                 (item) => item == "delete"
    //              );
    //              this.table_header.splice(table_index, 1);
    //           }
    //        } else {
    //           if (!this.table_header.includes("delete")) {
    //              this.table_header.push("delete");
    //              this.fields[7] = {
    //                 key: "delete",
    //              };
    //           }
    //        }
    //     }
    //  },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      "form.category_id"(newValue) {
        // Watch for changes in the selected category
        this.fetchSubCategory(newValue);
        // this.form.sub_category_id = null; // Reset subcategory selection
      },
      subCategoryDropdown(newVal) {
        if (!newVal) {
          this.form.sub_category_id = null;
        }
      },
      // activeTab(v) {
        // if (v) this.tabActive();
      // },
      "form.content_type"(v) {
        if (v == "pop_up") {
          this.form.ad_position = 1;
        }
      },
    },
    created() {
      this.fetchCategory();
      if (
        this.$route.name == "add-template" ||
        this.$route.name == "edit-template"
      ) {
        if (this.$route.name == "edit-template") {
          this.fetchpatient_library_template(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  